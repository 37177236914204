import { graphql } from 'gatsby'
import get from 'lodash/get'
import { Image } from './types'
import { ContentfulAsset } from './types/contentful'
import imageFallback from '../images/fallback.png'

export type ContentfulTypeResourceCategory = {
  /** UUID format */
  id: string
  /** ResourceCategory Name (short text) */
  name: string
  /** The carousel image */
  image: ContentfulAsset
  /** Created at time string */
  createdAt: string
}

export type ResourceCategory = {
  /** UUID format */
  id: string
  /** ResourceCategory Name (short text) */
  name: string
  /** The carousel image */
  image: Image
  /** Created at Date */
  createdAt: Date
}

export const fragment = graphql`
  fragment ResourceCategoryFields on ContentfulTypeResourceCategory {
    id
    name
    createdAt
    description {
      description
    }
    image {
      id
      description
      file {
        url
      }
    }
  }
`

export const transform = (
  node: ContentfulTypeResourceCategory
): ResourceCategory => ({
  id: node.id,
  name: get(node, 'name', ''),
  createdAt: new Date(node.createdAt),
  image: {
    src: get(node, 'image.file.url', imageFallback),
    alt: get(node, 'image.description', 'None provided'),
  },
})
