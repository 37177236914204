import * as React from 'react'
import cx from 'classnames'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import slugify from 'slugify'
import { Waypoint } from 'react-waypoint'
import anime from 'animejs'

import { CoverImage } from '../components/image'
import { ResourceList } from '../components/list'
import { ResourceHeader } from '../components/section'

import {
  transform as toGlobals,
  ContentfulSettingsDesignerFund,
} from '../data/globals'
import {
  transform as toResource,
  Resource,
  ContentfulTypeResource,
} from '../data/resource'
import { ResourceCategory } from '../data/resource-category'
import {
  transform as toResourcePage,
  ContentfulPageResources,
} from '../data/resource-page'
import { useDynamicTracking } from '../hooks'

interface Props {
  data: {
    allContentfulTypeResource: {
      edges: { node: ContentfulTypeResource }[]
    }
    allContentfulPageResources: {
      edges: { node: ContentfulPageResources }[]
    }
    allContentfulSettingsDesignerFund: {
      edges: { node: ContentfulSettingsDesignerFund }[]
    }
  }
}

type CategoryWithResources = ResourceCategory & {
  resources: Resource[]
}

type State = {
  currentCategory: number
  categories: CategoryWithResources[]
  revealed: { [name: string]: boolean }
  featureds: Resource[]
}

const anchorify = (s: string) => slugify(s, { lower: true })

const BACKGROUND_COLORS = [
  'var(--color-n95)',
  'var(--color-n85)',
  'var(--color-n95)',
  'var(--color-cookie)',
]

const StyledContentWrapper = styled.div`
  transition: background-color 1.5s ease-in-out;
  background-color: ${({ section }) =>
    BACKGROUND_COLORS[section % BACKGROUND_COLORS.length]};
`

const StyledSidebar = styled.aside`
  display: none;

  @media screen and (min-width: 1280px) {
    display: block;
    grid-row: span ${({ categoryCount }) => categoryCount};
    grid-column: 2 / 6;

    ul {
      top: 120px;
      position: sticky;
      margin: 120px 0;
      padding: 0;
      list-style: none;
      border-top: 1px solid currentColor;

      li {
        margin: 16px 0;
      }
    }

    a {
      opacity: 0.6;
      transition: opacity 180ms ease;
      text-decoration: none;

      &:hover {
        opacity: 1;
      }

      &.is-active {
        opacity: 1;
      }
    }
  }
`

const StyledCategorySection = styled.section`
  grid-column: 1 / -1;
  display: grid;
  grid-auto-flow: dense;
  grid-template-columns: repeat(6, 1fr);
  grid-column-gap: 16px;
  grid-auto-rows: auto;
  padding: 60px 0;

  & > figure {
    grid-column: 1 / -1;
    margin: 0;
  }

  & > div {
    grid-column: 1 / -1;
  }

  .cover-image {
    padding-bottom: ${Math.floor((322 / 317) * 100)}%;
  }

  @media screen and (max-width: 767px) {
    figure {
      margin-bottom: 32px;
    }

    .cover-image {
      width: calc(100% - 24px);
    }

    &:nth-child(odd) {
      .cover-image {
        margin-left: 40px;
      }
    }
    &:nth-child(even) {
      .cover-image {
        margin-left: -16px;
      }
    }
  }

  @media screen and (min-width: 768px) {
    grid-column: 2 / -2;
    grid-template-columns: repeat(22, 1fr);
    padding: 90px 0;
    min-height: 70vh;

    & > div {
      /* padding-top: 80px; */
    }

    .cover-image {
      padding-bottom: ${Math.floor((520 / 407) * 100)}%;
    }

    &:nth-child(odd) {
      & > div {
        grid-column: 1 / 12;
      }
      & > figure {
        grid-column: 13 / -1;
      }
    }
    &:nth-child(even) {
      & > figure {
        grid-column: 1 / 11;
      }
      & > div {
        grid-column: 12 / -1;
      }
    }
  }

  @media screen and (min-width: 1280px) {
    grid-column: 7 / -2;
    grid-template-columns: repeat(17, 1fr);
    /* grid-template-rows: 1fr auto 1fr; */
    padding: 120px 0;
    /* min-height: 80vh; */

    & > figure {
      /* grid-row: 2; */
      /* margin-top: -160px; */
      & > * {
        position: sticky;
        top: 120px;
      }
    }
    & > div {
      padding-top: 48px;
      padding-bottom: 48px;
      /* grid-row: 3; */
    }

    .cover-image {
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1);
    }
    .resource-list__header {
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1) 200ms;
    }
    .resource-list {
      transition: all 800ms cubic-bezier(0.19, 1, 0.22, 1) 400ms;
    }

    &:nth-child(odd) {
      & > div {
        grid-column: 1 / 10;
      }
      & > figure {
        grid-column: 11 / -1;
      }
    }
    &:nth-child(even) {
      & > figure {
        grid-column: 1 / 8;
      }
      & > div {
        grid-column: 9 / -1;
      }
    }

    &.x-waypoint {
      .cover-image,
      .resource-list__header,
      .resource-list {
        transform: translateY(50px);
        opacity: 0;
      }
    }
  }
`

const resourcesByCategory = (
  resources: Resource[],
  categories: ResourceCategory[]
) => {
  const result: CategoryWithResources[] = categories.reduce((acc, cat) => {
    const categoryResources = resources.filter(
      res => res.category.id === cat.id
    )
    const sortedResources = categoryResources.sort((a, b) => {
      return b.createdAt.getTime() - a.createdAt.getTime()
    })
    const withResources: CategoryWithResources = {
      ...cat,
      resources: sortedResources,
    }

    acc.push(withResources)
    return acc
  }, [] as CategoryWithResources[])
  return result
}

export default class ResourcesPage extends React.Component<Props, State> {
  constructor(props) {
    super(props)

    const {
      allContentfulSettingsDesignerFund: { edges: globals },
      allContentfulTypeResource: { edges: resources },
      allContentfulPageResources: { edges: template },
    } = props.data

    const [settings] = globals.map(({ node }) => toGlobals(node))
    const rezs = resources.map(({ node }) => toResource(node))
    const [page] = template.map(({ node }) => toResourcePage(node))
    const { categories } = page
    const combined = resourcesByCategory(rezs, categories)
    const withPosts = combined.filter(c => c.resources && c.resources.length)

    const revealed = withPosts.reduce(
      (acc, cur) => ({ ...acc, [anchorify(cur.name)]: false }),
      {}
    )

    this.state = {
      currentCategory: 0,
      categories: withPosts,
      revealed,
      featureds: settings.resources,
    }
  }

  onClickSidebar(id: string) {
    const el = document.querySelector(`#${id}`)
    const container =
      window.document.scrollingElement ||
      window.document.body ||
      window.document.documentElement
    if (history && history.pushState) history.pushState(null, null, '#' + id)
    if (el && container) {
      anime({
        duration: 2000,
        easing: 'easeOutExpo',
        scrollTop: el.offsetTop,
        targets: container,
      })
    }
  }

  public render() {
    const { currentCategory, revealed, categories, featureds } = this.state
    const [onTrack] = useDynamicTracking({
      action: 'Click Sidebar',
      category: 'Resources Page',
    })
    return (
      <>
        <ResourceHeader resources={featureds} />
        <StyledContentWrapper section={currentCategory}>
          <div className="container container--grid">
            <StyledSidebar categoryCount={categories.length}>
              <ul className="type-p-md">
                {categories.map((c, idx) => (
                  <li key={c.name}>
                    <a
                      href={'#' + anchorify(c.name)}
                      style={
                        currentCategory === idx ? { opacity: 1 } : undefined
                      }
                      onClick={e => {
                        e.preventDefault()
                        onTrack({ label: c.name })
                        this.onClickSidebar(anchorify(c.name))
                      }}
                    >
                      {c.name}
                    </a>
                  </li>
                ))}
              </ul>
            </StyledSidebar>
            {categories.map((c, idx) => {
              const id = anchorify(c.name)
              return (
                <StyledCategorySection
                  key={id}
                  id={id}
                  className={cx({ 'x-waypoint': !revealed[id] })}
                >
                  <figure>
                    <CoverImage
                      className="cover-image--ratio"
                      src={c.image.src}
                      alt={c.image.alt}
                    />
                  </figure>
                  <div>
                    <Waypoint
                      onEnter={() =>
                        this.setState(s => ({
                          currentCategory: idx,
                          revealed: { ...s.revealed, [id]: true },
                        }))
                      }
                    />
                    <ResourceList.Header
                      heading={c.name}
                      subheading={`Category ${
                        idx < 9 ? '0' + (idx + 1) : idx + 1
                      }`}
                    />
                    <ResourceList>
                      {c.resources.map(r => (
                        <ResourceList.Item
                          key={r.url}
                          title={r.title}
                          slug={r.url}
                        />
                      ))}
                    </ResourceList>
                  </div>
                </StyledCategorySection>
              )
            })}
          </div>
        </StyledContentWrapper>
      </>
    )
  }
}

export const query = graphql`
  query ResourcesPage {
    allContentfulTypeResource(
      sort: { fields: [title] }
      filter: {
        category: { id: { ne: null } }
        title: { ne: null }
        url: { ne: null }
      }
    ) {
      edges {
        node {
          ...ResourceFields
        }
      }
    }
    allContentfulPageResources {
      edges {
        node {
          ...ResourcePageFields
        }
      }
    }
    allContentfulSettingsDesignerFund(limit: 1) {
      edges {
        node {
          ...GlobalsFields
        }
      }
    }
  }
`
