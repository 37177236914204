import { graphql } from 'gatsby'
import get from 'lodash/get'
import {
  transform as toResourceCategory,
  ResourceCategory,
  ContentfulTypeResourceCategory,
} from './resource-category'

export type ContentfulPageResources = {
  id: string
  categories: ContentfulTypeResourceCategory
}
export type ResourcePage = {
  id: string
  categories: ResourceCategory[]
}

export const fragment = graphql`
  fragment ResourcePageFields on ContentfulPageResources {
    id
    categories {
      ...ResourceCategoryFields
    }
  }
`

export const transform = (node: ContentfulPageResources): ResourcePage => ({
  id: node.id,
  categories: get(node, 'categories', []).map(res => toResourceCategory(res)),
})
